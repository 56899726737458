<template>
  <v-app>
    <header>
      <v-app-bar fixed class="shadow" color="transparent" flat dark>
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="navbtn"
        ></v-app-bar-nav-icon>

        <v-toolbar-title
          ><h1 class="display-2" style="font-weight: bold">
            GradeEdu
          </h1></v-toolbar-title
        >

        <v-spacer></v-spacer>

        <v-tabs style="max-width: 450px" right color="white" class="navmenu">
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="item in items"
            :key="item"
            class="px-7 display-1 white--text"
            style="font-weight: bold"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-btn class="display-1 ml-7" color="success">Log In</v-btn>

        <template v-slot:extension>
          <v-tabs centered color="white" dark optional class="navmenu">
            <v-tab
              v-for="item in items1"
              :key="item"
              class="px-7 display-1 white--text"
              style="font-weight: bold"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </header>

    <v-navigation-drawer absolute temporary v-model="drawer" dark>
      <v-list shaped>
        <v-subheader class="text-h4">MENU</v-subheader>
        <v-list-item-group v-model="selectedItem" color="red">
          <v-list-item v-for="(item, i) in items1" :key="i">
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader class="text-h4">Others</v-subheader>
          <v-list-item v-for="(item, i) in items" :key="i">
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <section>
        <v-img src="/bc2.jpg" class="center">
          <h1 class="white--text text-h1 title">
            Graduate Education of Mongolia
          </h1>
        </v-img>
      </section>
      <section style="background-color: #2f2424">
        <v-container fluid class="text-center py-16">
          <h1 class="display-4 white--text" style="font-weight: bold">
            Арга Хэмжээнүүд
          </h1>
          <p class="display-1 white--text mb-10">
            Монгол улсын Эрдэмийн их сургуулийн арга хэмжээнүүд
          </p>
          <v-row>
            <v-col cols="6" md="3" lg="3" sm="6" v-for="n in 4" v-bind:key="n">
              <v-card @click="_goDetail">
                <v-img :src="'/card' + n + '.jpg'"></v-img>
                <v-card-title class="display-2 red--text">Lecture</v-card-title>
                <v-card-subtitle
                  class="text-start display-1 black--text pb-0"
                  style="font-weight: bold"
                  >Боловсролын доктор xамгаалтын ажил</v-card-subtitle
                >
                <v-card-subtitle class="text-start pt-1"
                  >10:00AM</v-card-subtitle
                >
              </v-card>
            </v-col>
          </v-row>
          <v-btn color="#960206" dark class="my-8 text-button"
            >More Stanford Events</v-btn
          >
        </v-container>
      </section>
      <section>
        <v-container fluid class="text-center py-16">
          <h1 class="display-4 mb-2" style="font-weight: bold">Research</h1>
          <p class="display-2 mb-10">
            A culture of collaboration that drives innovative discoveries vital
            <br />
            to our world, our health and our intellectual life
          </p>

          <v-row
            justify="center"
            style="border-top: 1px solid black; border-bottom: 1px solid black"
          >
            <v-col cols="12" md="3" lg="3" sm="12">
              <h1 class="text-h2" style="font-weight: bold">18 Institutes</h1>
              <span class="text-h4" style="font-weight: 400"
                >cross interdisciplinary boundaries</span
              >
            </v-col>
            <v-col cols="6" md="3" lg="3" sm="6">
              <h1 class="text-h2" style="font-weight: bold">20 Libraries</h1>
              <span class="text-h4" style="font-weight: 400"
                >hold over 9.5 million volumes</span
              >
            </v-col>
            <v-col cols="6" md="3" lg="3" sm="6">
              <h1 class="text-h2" style="font-weight: bold">$1.93 Billion</h1>
              <span class="text-h4" style="font-weight: 400"
                >annual research budget</span
              >
            </v-col>
          </v-row>

          <h1 class="display-3 my-10" style="font-weight: bold">
            Stanford research for a better world
          </h1>
          <v-row>
            <v-col
              cols="6"
              md="3"
              lg="3"
              sm="6"
              v-for="n in 4"
              v-bind:key="n"
              class=""
            >
              <v-card>
                <v-img :src="'/card-' + n + '.jpg'"></v-img>
                <v-card-title class="display-1 red--text"
                  >HUMANITIES
                </v-card-title>
                <v-card-subtitle
                  class="text-start text-h5 black--text pb-7"
                  style="font-weight: bold"
                  >Examining systemic racism, advancing racial
                  equity</v-card-subtitle
                >
              </v-card>
            </v-col>
          </v-row>
          <v-btn color="#960206" dark class="mt-16 text-button"
            >More About Research</v-btn
          >
        </v-container>
      </section>
      <section>
        <v-container fluid class="text-center mb-16">
          <h1 class="display-4 mb-3" style="font-weight: bold">Academics</h1>
          <p class="display-1 mb-10" style="line-height: 90%">
            Preparing students to make meaningful contributions to society as
            engaged citizens and leaders in a complex world
          </p>
          <v-row>
            <v-col cols="12" md="4" lg="4" sm="6" v-for="n in 3" v-bind:key="n">
              <v-card flat color="transparent">
                <v-img :src="'/card--' + n + '.jpg'"></v-img>
                <v-card-title class="text-h4" style="font-weight: bold"
                  >Undergraduate Education
                </v-card-title>
                <v-card-subtitle class="text-start text-h5 black--text pb-0"
                  >Rich learning experiences that provide a broad liberal arts
                  foundation and deep subject-area expertise
                </v-card-subtitle>
                <v-card-subtitle class="text-start text-h6 pb-0">
                  <a href="">Undergraduate Education</a
                  ><span style="font-size: 1.4em"> ></span>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section>
        <v-container fluid class="mb-16">
          <v-row>
            <v-col
              v-for="n in 8"
              v-bind:key="n"
              md="3"
              lg="3"
              sm="6"
              cols="12"
              align="center"
            >
              <v-card class="pb-2">
                <v-img :src="'/img' + n + '.jpg'" :aspect-ratio="1 / 1"></v-img>
                <v-card-title class="text-h5">Cafe Badilico</v-card-title>

                <v-card-text class="mt-2">
                  <v-row align="center" class="mx-0">
                    <v-rating
                      :value="4.5"
                      color="amber"
                      dense
                      half-increments
                      readonly
                      size="14"
                    ></v-rating>

                    <div class="grey--text ml-4">4.5 (413)</div>
                  </v-row>
                </v-card-text>
                <v-img
                  :src="'/map' + n + '.png'"
                  :aspect-ratio="1 / 1"
                  contain
                  class="mx-10"
                ></v-img>
                <v-btn outlined tile class="px-6 my-2" @click="_goDetail"
                  >Explore</v-btn
                >
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section style="background-color: #fff" class="py-16">
        <v-container fluid class="text-center mb-16">
          <h1 class="text-h6 mb-3">PLANS</h1>
          <p class="text-h2 mb-10">Pricing</p>
          <p class="text-h4 mb-10">
            Choose any of the following plans to get started with. You can begin
            with the<br />
            FREE plan to see our web application at first. You can always change
            your <br />plan at any time.
          </p>
          <v-btn
            style="border-radius: 30px 5px 5px 30px; width: 110px"
            class="text-button"
            depressed
            >Monthly</v-btn
          >
          <v-btn
            style="border-radius: 5px 30px 30px 5px; width: 110px"
            class="text-button"
            depressed
            >Yearly</v-btn
          >
          <v-row class="mt-8">
            <v-col cols="12" md="4" lg="4" sm="6" v-for="n in 3" v-bind:key="n">
              <v-card elevation="6" class="py-4">
                <v-card-subtitle class="text-center text-h5 black--text pb-0"
                  >Personal
                </v-card-subtitle>
                <v-card-subtitle
                  class="text-center py-10"
                  style="position: relative"
                >
                  <span
                    class="green-text"
                    style="
                      top: 4.2em;
                      font-size: 75%;

                      position: absolute;
                    "
                    >$</span
                  >
                  <span
                    class="text-h1 green--text ml-1"
                    style="font-weight: bold"
                    >50</span
                  >
                  <span class="black--text">/mo</span>
                </v-card-subtitle>
                <v-card-subtitle class="text-center text-h5 grey--text pt-0">
                  100+ Components <br />
                  Private Support <br />
                  Unlimited Projects
                </v-card-subtitle>

                <v-btn class="text-button my-5" color="success"
                  >Get Started</v-btn
                >
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-main>
    <v-footer dark class="" style="background: #151f42">
      <v-row justify="center" no-gutters>
        <v-col cols="12" class="pa-0 pt-4">
          <h1 class="text-center text-h3" style="font-weight: bold">
            Xолбоо бариx
          </h1>
        </v-col>
        <v-col cols="12" class="pa-0">
          <p class="text-center text-caption mt-4">
            Монгол улс, УБ хот, СБД 8-р хороо, Бага тойруу,
            <br />
            Сүхбаатарын талбай 20/6, Эрдмийн ордон
          </p>
        </v-col>
        <v-col>
          <v-row justify="center" class="py-4">
            <v-btn icon x-large color="primary" target="blank">
              <a
                style="text-decoration: none"
                href="https://www.facebook.com/graduateuniversity"
                target="blank"
                ><v-icon>mdi-twitter</v-icon></a
              >
            </v-btn>
            <v-btn icon x-large color="primary" target="blank">
              <a
                style="text-decoration: none"
                href="https://www.facebook.com/graduateuniversity"
                target="blank"
                ><v-icon>mdi-facebook</v-icon></a
              >
            </v-btn>
            <v-btn icon x-large color="primary">
              <a
                style="text-decoration: none"
                href="http://gradedu.mn/#"
                target="blank"
                ><v-icon>mdi-instagram</v-icon></a
              >
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="12" class="pa-0">
          <h1 class="text-center text-h5" style="font-weight: normal">
            Phone: (+976) 7600-2010, 7600-3010
          </h1>
        </v-col>
        <v-col cols="12" class="pa-1">
          <p class="text-center text-h6">Email: info@gradedu.mn</p>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>
<style>
.title {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.center {
  position: relative;
}

.shadow {
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, transparent);
}
body {
  line-height: 1.5;
  font-family: Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.8em;
  color: #000;
}
.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}
@media only screen and (min-width: 1100px) {
  .navbtn {
    visibility: hidden;
  }
}
@media only screen and (max-width: 1100px) {
  .navmenu {
    display: none;
  }
}
</style>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
    items: ["Home", "Pricing", "About Us"],
    items1: [
      "News",
      "Events",
      "Academics",
      "Research",
      "Health Care",
      "Campus Life",
      "Admission",
      "About",
    ],
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    _goDetail() {
      this.$router.push({
        name: "TourDetail",
        params: { itemId: "asasdfsd" },
      });
    },
  },
};
</script>